import createStore, { conditionalStore } from '#/store'
import useInDevMode from './useInDevMode.js'


export type FeatureName = typeof features[number]

const features = [
  'symptoms',
  'assessment',
  'page:condition',
  'ads',
  'caching',
  'components',
] as const

type DevFeatureState = {
  toggle: (view: FeatureName, active?: boolean) => void
  state: Record<FeatureName, {
    active: boolean
    count: number
  }>,
}

const store = createStore<DevFeatureState>(set => ({
  toggle: (feature, active) => set(s => {
    const state = s.state[feature]

    if (state) {
      active ??= !state.active
      if (active) {
        state.count++
        state.active = true

      } else {
        state.count--
        state.active = state.count > 0
      }
    }
  }),

  state: features.toObject(feature => [feature, {
    active: false,
    count: 0,
  }]),
  
}))

export default conditionalStore(store, () => useInDevMode())
